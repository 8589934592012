<template>
  <div>
    <div>
      <v-btn
        @click="changeLanguage('en'); $store.commit('setFadeMenu', false);"
        :class="(selectLanguage == 'en') ? 'mon-medium button-select-language-active mb-2' : 'mon-medium button-select-language mb-2'"
        elevation="0"
      >
        <img
          class="mr-2"
          src="@/assets/images/Flags_usa.svg"
          alt=""
          width="37"
          height="25"
        >
        English
      </v-btn>
    </div>
    <div>
      <v-btn
        @click="changeLanguage('sp'); $store.commit('setFadeMenu', false);"
        :class="(selectLanguage == 'sp') ? 'mon-medium button-select-language-active mt-2' : 'mon-medium button-select-language mt-2'"
        elevation="0"
      >
        <img
          class="mr-2"
          src="@/assets/images/Flags_mex.svg"
          alt=""
          width="37"
          height="25"
        >
        Español
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuComponent",
  data() {
    return {
      //VARIABLES
      texts: "",
    };
  },
  beforeMount() {
    // this.texts = FILE.menu[this.selectLanguage];
  },
  methods: {
    changeLanguage: function (e) {
      const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        },
        payload = {};

        if(this.$store.state.sUserId){
          DB.patch(
            `${URI}/api/v1/${e}/users/${this.$store.state.sUserId}/languages`,
            payload,
            config
          )
            .then((response) => {
            })
            .catch((error) => {
              this.mixError(error.response.data.message, error.response.status);
            });
        }
          this.$store.commit("toggle_language", e);

    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        // this.texts = FILE.menu[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.button-select-language {
  width: 100%;
  height: 40px !important;
  background-color: transparent !important;
  border-radius: 10px;
  opacity: 1;
  color: #283c4d !important;
  font-size: 14px !important;
  text-transform: capitalize;
  justify-content: flex-start;
}

.button-select-language-active {
  width: 100%;
  height: 40px !important;
  background: #283c4d 0% 0% no-repeat padding-box !important;
  border-radius: 10px;
  opacity: 1;
  color: #ffffff !important;
  font-size: 14px !important;
  text-transform: capitalize;
  justify-content: flex-start;
}
</style>